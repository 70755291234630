"use client";
import { apiSlice } from "@/store/api/apiSlice";

export enum SubscriptionEnum {
	TRIAL = "TRIAL",
	RENEWAL = "RENEWAL",
	NEW = "NEW",
	REFUND = "REFUND",
}

export interface ISubscriptionData {
	id: string;
	subscription_type: keyof typeof SubscriptionEnum;
	plan_id: number;
	program_id: number;
	user_id: string;
	therapist_id: string;
	total_sessions: number;
	sessions_completed: number;
	last_purchase: Date;
	emergency_cancellations: number;
	total_emergency_cancellations: number;
	expiry: string;
	log: string;
	plan: {
		id: number;
		planName: string;
		noOfSession: number;
		originalPrice: number;
		sellingPrice: number;
		programId: number;
		isPopular: boolean;
		published: boolean;
		plan_type: string;
		discount_category: string;
		features: { available: boolean; featureTitle: string }[];
	};
	program: {
		features: string[];
		id: 1;
		thumbnail: string;
		program_name: string;
		startingPrice: 3999;
		subtitle: string;
		published: boolean;
		session_duration: number;
	};
}

export interface ISubscriptionTypes {
	subscriptions: ISubscriptionData[];
}

export const subscriptionApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getSubscription: builder.query<ISubscriptionTypes, string>({
			query: () => ({
				url: "/subscription/list/user",
				method: "GET",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				credentials: "include" as const,
			}),
		}),
		reqSessionExemption: builder.query<any, { id: string }>({
			query: (id) => ({
				url: `/subscription/request/subscription-extend-expiry/${id}`,
				method: "GET",
				headers: {
					Authorization: `Basic  ${Buffer.from(
						process.env.NEXT_PUBLIC_URL_API_USERNAME +
							":" +
							process.env.NEXT_PUBLIC_URL_API_PASSWORD,
					).toString("base64")}`,
				},
				credentials: "include" as const,
			}),
		}),
	}),
});

export const { useGetSubscriptionQuery, useReqSessionExemptionQuery } =
	subscriptionApi;
export default subscriptionApi;
